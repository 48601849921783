<template>
  <div class="product-box">
    <div class="head-box animated zoomIn">
      <div class="content">
        <div class="title animated fadeInDown">不良事件智能上报</div>
        <div class="tip animated fadeInUp">多安不良事件上报系统遵照PDCA全面质量循环管理方法,能满足二级及三级医院，助力医院不良事件上报效率提高,促进实现医院安全医疗的目标</div>
      </div>
    </div>

    <div class="box">
      <div class="title animated backInDown">
        医疗安全（不良）事件闭环业务体系
      </div>
      <img class="img animated bounceInUp" style="width: 600px;" src="@/assets/img/product/20.png" />
    </div>

    <div class="box" v-for="val in initData" :key="val.id" :id="val.id">
      <div class="title animated" :style="{ visibility: val.show ? 'visible':'hidden' }" :class="{ bounceInLeft: val.show }">{{val.title}}</div>
      <div class="desc animated" :style="{ visibility: val.show ? 'visible':'hidden' }" :class="{ bounceInRight: val.show }">{{val.desc}}</div>
      <img class="img  animated" :style="{ visibility: val.show ? 'visible':'hidden', width: val.width }" :class="{ bounceInUp: val.show }" :src="val.img" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      initData: [
        {
          id: 'animatedBox1',
          show: false,
          title: '事件上报-类型全面，结构化表单，支持自定义',
          desc: '不良事件类型模板满足全院各个科室的需求，覆盖全院所有科室。',
          img: require('@/assets/img/product/21.png'),
          width: '80%',
        },
        {
          id: 'animatedBox2',
          show: false,
          title: '事件流转-标准化流程，全过程管理',
          desc: '支持标准化流转模式，上报至归口科室与质控科审批处理，支持事件追踪，发现存在问题，提出整改措施，实现持续改进。',
          img: require('@/assets/img/product/22.png'),
          width: '80%',
        },
        {
          id: 'animatedBox3',
          show: false,
          title: '事件统计分析-自动化数据统计，智能分析展现',
          desc: '事件的统计分析包括系统自动汇总保存的报告数据，导入现代化的质量管理工具进行科学化分析。',
          img: require('@/assets/img/product/23.png'),
          width: '100%',
        },
        {
          id: 'animatedBox4',
          show: false,
          title: '移动上报-多途径待办提醒，移动办公更高效',
          desc: '支持手机短信、邮件、平台短消息、钉钉及企业微信等多途径，全方位向待跟进人员发送信息提醒，可以随时随地接受和处理各类不良事件。',
          img: require('@/assets/img/product/24.png'),
          width: '500px',
        },
        {
          id: 'animatedBox5',
          show: false,
          title: '事件监测提醒-主动监测预警，智能提醒上报',
          desc: '基于NLP语义分析知识图谱智能监测展示技术，对接医院业务系统，实现不良事件主动上报提醒。',
          img: require('@/assets/img/product/25.png'),
          width: '500px',
        },
        {
          id: 'animatedBox6',
          show: false,
          title: '事件分享-营造全院学习、主动上报的氛围',
          desc: '支持进行事件的公告分享，进行学习讨论，形成思维定式、行为习惯，预防下一次不良事件发送，营造全院学习和主动上报的氛围。',
          img: require('@/assets/img/product/26.png'),
          width: '600px',
        },
      ],
    }
  },
  created() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // 可选，平滑滚动效果
    });
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.initData.forEach((v) => {
        let element = document.getElementById(v.id)
        // 获取元素的位置和尺寸
        let rect = element.getBoundingClientRect();
        // 计算元素和浏览器窗口底部之间的距离
        let distance = window.innerHeight - rect.bottom;
        if (distance > -300) {
          v.show = true
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.product-box {
  height: 100%;
  padding-top: 68px;
  .head-box {
    position: relative;
    width: 100%;
    height: 600px;
    background: url("~@/assets/img/product/1.png") no-repeat center/cover;
    margin-bottom: 100px;
    .content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      padding: 100px 12% 40px;
      font-family: "楷体", "楷体_GB2312";
    }
    .title {
      font-size: 100px;
      font-weight: 500;
      text-shadow: 0 0 1px currentColor, -1px -1px 1px #000, 0 -1px 1px #000,
        1px -1px 1px #000, 1px 0 1px #000, 1px 1px 1px #000, 0 1px 1px #000,
        -1px 1px 1px #000, -1px 0 1px #000;
    }
    .tip {
      margin-top: 32px;
      font-size: 32px;
    }
  }
  .box {
    max-width: 1100px;
    margin: 0 auto;
    margin-bottom: 80px;
    text-align: center;
    .title {
      font-size: 32px;
      font-weight: 500;
      color: #17b3a3;
      margin-bottom: 20px;
    }
    .desc {
      font-size: 24px;
      font-weight: 500;
      color: #333;
      margin-bottom: 40px;
    }
  }
}
</style>
